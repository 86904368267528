@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Pangolin&display=swap');

.navbar .navbar.show {
  background-color: white;
}


.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px; 
  z-index: 1001;
}

.content {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter' !important;

  font-style: normal;

  color: #001400;

  background-color: white;

}
/* custom scroll bar */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::selection{
  background: rgb(0,123,255,0.3);
}
.content{
  max-width: 1250px;
  margin: auto;
  padding: 0 20px;
}
.navbar{
  position: fixed;
  width: 100%;
  z-index: 2;
  padding: 20px 0;
  transition: all 0.3s ease;
  background: white;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
}

.navbar.sticky{
   padding: 10px 0;
}
.navbar .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar .logo a{
  color: black;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
  padding-right: 20px;
}
.navbar .menu-list{
  display: inline-flex;
}
.menu-list li {
   display: flex;
   align-items: center;
 }
 
 .menu-list li a {
   color: black;
   font-size: 18px;
   font-weight: 500;
   margin-left: 25px;
   text-decoration: none;
   transition: all 0.3s ease;
   padding-top: 10px;
 }
.menu-list li a:hover{
  color: #FAE100;
}
.banner{
   background-color: black;
position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.about{
  padding: 30px 0;
}
.about .title{
  font-size: 38px;
  font-weight: 700;
}
.about p{
  padding-top: 20px;
  text-align: justify;
}
.icon{
  /* color: #fff; */
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.menu-list .cancel-btn{
  position: absolute;
  right: 30px;
  top: 20px;
}
@media (max-width: 1230px) {
  .content{
    padding: 0 60px;
  }
}
@media (max-width: 1100px) {
  .content{
    padding: 0 40px;
  }
}

@media (max-width: 1180px) {
  body.disabled{
    overflow: hidden;
  }
  .icon{
    display: block;
  }
  .icon.hide{
    display: none;
  }
  .navbar .menu-list{
    position: fixed;
    height: 120vh;
    width: 100%;
    max-width: 300px;
    left: -100%;
    top: 0px;
    display: block;
    padding: 10px 0;
    text-align: center;
    background: #1f4c48;
    transition: all 0.3s ease;
    color: #FAE100;
    align-items: center;
  }
  .navbar.show .menu-list{
    left: 0%;
  }
  .navbar .menu-list li{
    margin-top: 45px;
  }
  .navbar .menu-list li a{
    font-size: 23px;
    color: WHITE;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar.show .menu-list li a{
    margin-left: 0px;
  }
}
@media (max-width: 380px) {
  .navbar .logo a{
    font-size: 27px;
  }
}

.menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-list li {
  display: block; /* Display items in block format, one per line */
  text-align: center;
}

.menu-list li a {
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  padding-top: 10px;
}

.menu-list li a:hover {
  color: #FAE100;
}

 