/* Resetting styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Footer styles */
.footer-background-color {
  background-color: #1f4c48;
  min-height: 50px;
}

.PhoneNumber{
  color:white;
}


.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content h3 {
  font-weight: 50px;
  font-size: 50px;
  line-height: 77px;
  margin: 2rem 0 0.6rem 0;
  color: white;
}

.footer-content p {
  margin: 12px auto;
  line-height: 28px;
  font-size: 14px;
}
.button-footer {
  background: #001400;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 16px 24px; /* Adjust padding as needed */
  cursor: pointer;
  margin:10px;
}

.button-footer:hover {
  background-color: #fae100;
  transition: background-color 0.3s, transform 0.3s;
  animation: pulse 1s infinite alternate;
}



.emails {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  width: 200px;
  height: auto;
  color: white;
}
.emails li {
  margin: 0 20px;
  color: white;
}


.emails a {
  height: auto;
  color: white;
}

.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  width: 200px;
  height: auto;
  color: white;
}

.socials li {
  margin: 0 20px;
  color: white;
}

.socials a {
  height: auto;
  color: white;
}

/* Font Awesome icon styles */
.socials a svg {
  font-size: 24px;
  fill: #fff; /* Set icons to white */
  transition: all 0.3s ease; /* Smooth transition effect */
}

.socials a svg:hover {
  transform: scale(1.2); /* Increase size on hover */
  fill: yellow; /* Change color on hover */
}

.footer-content .button {
  text-align: center;
}

.footer-bottom p {
  word-spacing: 2px;
  color: grey;
  padding: 20px;
}
