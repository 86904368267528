@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Pangolin&display=swap');

body {

  font-family: 'Inter' !important;

  font-style: normal;

  color: #001400;

}