.yellow-border {
  height: 48px;
  border-left: 4px solid #fae100;
}
.form-control {
  border-color: #000000 !important;
  box-shadow: 0 0 0 0.25rem #ffffff !important;
}
.form-control:focus {
  border-color: #000000 !important;
  box-shadow: 0 0 0 0.25rem #ffffff !important;
}
@media (min-width: 700px) and (max-width: 1000px) {
  .margin-left-70 {
    margin-left: -40px;
  }
}
@media (max-width: 690px) {
  .yellow-border {
    display: none;
  }
}
@media (min-width: 1001px) {
  .margin-left-70 {
    margin-left: -70px;
  }
}


.title-bold{
  font-weight: 700;
  font-size: 64px;
}


.title {
  font-weight: 300;
  font-size: 64px;
  line-height: 77px;
  padding-bottom: 32px;
}
@media (max-width: 1200px) {
  .bg-yellow-vertical-circle {
    background-position-y: center;
  }
}


.subheading {
  font-weight: 350;
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 20px;
}


.subheading-gray {
  font-weight: 350;
  font-size: 24px;
  padding-bottom: 10px;
  color: rgb(87, 87, 87);
  margin-top: -55px;
}

.margin-top-negative{
  padding-top: 40px;
}

.para {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.para-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

.disclaimer {
  font-style: italic;
font-weight: 400;
font-size: 14px;
line-height: 190%;
padding-bottom: 22px;
}

.highlight-hd {
  background-color: #fae100;
  padding: 2px;
  border-radius: 3px;
}

.discover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px 12px 25px;
  gap: 4px;
  background: #001400;
  border-radius: 30px;
  border: none;
  padding-top: 2;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}



.enroll-now {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px 12px 26px;
  gap: 4px;
  background: #001400;
  border-radius: 30px;
  border: none;


  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.contact-now {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 18px 12px 24px;
  gap: 4px;
  background: #001400;
  border-radius: 30px;
  border: none;
  margin-top: 20px;

  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.div-gray-odd {
  background-color: #F2F2F2;
}

.div-white-even {
  background-color: white;
}

.color-gray{
  color: #001400;
  opacity: 0.5;
  cursor: pointer;
}
.subheading-bold {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 20px;
}