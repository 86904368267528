* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

html {
  scroll-behavior: smooth;
}


.title {
  font-weight: 100;
  font-size: 64px;
  line-height: 77px;
  padding-bottom: 32px;
}

.titlePartner{
  font-weight: 100;
  font-size: 64px;
  line-height: 77px;
  padding-bottom: 32px;
  text-align: center;
}

.titleCenter {
  font-weight: 100;
  font-size: 64px;
  line-height: 77px;
  padding-bottom: 32px;
  text-align: center;
}

.partnerBoxes {
  display: flex;
  justify-content: space-evenly;
}

.partnerBox {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  border-radius: 15px;
  width: 300px;
  height: 200px;
  perspective: 1000px;
  margin: 15px;
}

.partnerInner {
  position: relative;
  border-radius: 15px;
  background-color: #dedede;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.partnerBox:hover .partnerInner {
  transform: rotateY(180deg);
}


.partnerBox:hover .partnerInner {
  transform: rotateY(180deg);
}




.partnerFront, .partnerBack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
}
.partnerFront {
  background-color: #ffffff;
  color: #001400;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px;
}

.partnerBack {
  font-size: 14px; /* Smaller font size for a compact look */
  font-weight: 400;
  transform: rotateY(180deg);
  padding: 20px;
  text-align: center;
  background-color: #fff9db; /* Super light yellow for a soft look */
  color: #001400; /* Dark text for excellent contrast */
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  line-height: 1.5; /* Adjusted line spacing for readability with smaller text */
}

.partnerBack:hover {
  background-color: #fff4c2; /* Slightly darker yellow for hover effect */
  color: #001400; /* Keeping text consistent on hover */
  transition: background-color 0.3s ease, color 0.3s ease;
}



@media only screen and (max-width: 600px) {
	.partnerBoxes {
		flex-direction: column; 
		align-items: center; 
		gap: 20px; 
	}

	.partnerBox {
		width: 90%; 
		height: auto; 
		margin-bottom: 20px; 
	}

	.partnerInner {
		height: 200px; 
	}

	.partnerFront, .partnerBack {
		padding: 10px; 
	}
}


@media only screen and (max-width: 375px) {
	.partnerBox {
		width: 100%; 
		height: 180px; 
	}

	.partnerInner {
		height: 180px; 
	}
}

@media (max-width: 1200px) {
  .bg-yellow-vertical-circle {
    background-position-y: center;
  }
} 

@media only screen and (max-width: 1100px) and (min-width: 300px){
  html, body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

  }
}


.subheading {
  font-weight: 350;
  font-size: 24px;
  line-height: 20px;
  padding-bottom: 20px;
}

.subheading-question {
  font-weight: 350;
  font-size: 24px;
  line-height: 40px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .highlight-hd {
      line-height: 1.9; 
  }
}





.para {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.para-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

.disclaimer {
  font-style: italic;
font-weight: 400;
font-size: 14px;
line-height: 190%;
padding-bottom: 22px;
}

.highlight-hd {
  background-color: #fae100;
  padding: 2px;
  border-radius: 3px;
}

.button-everywhere {
  background: #001400;
  border: none;
  border-radius: 30px;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  line-height: 22px;
  padding: 12px 18px;
  cursor: pointer;
}

/* Animation keyframes for pulsating effect */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Hover state for background color change */
.button-everywhere:hover {
  background-color: #fae100;
  transition: background-color 0.3s, transform 0.3s;
  animation: pulse 1s infinite alternate;
}



.div-gray-odd {
  background-color: #F2F2F2;
}

.div-white-even {
  background-color: white;
}

.color-gray{
  color: #001400;
  opacity: 0.5;
  cursor: pointer;
}
.subheading-bold {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 20px;
}

.title-courses{
    font-weight: 300;
    font-size: 64px;
    line-height: 77px;
    padding-bottom: 32px;
    text-align: center;
}

.card-white {
  height: 315px;
  width: 332.48px;
  background: white;
  border-radius: 20px;
  padding-top: 15%;
  transition: opacity 0.5s ease;

}

.card-container {
  position: relative; /* Add this line */
}

.card-container:hover .card-white {
  opacity: 0;
}

.card-container:hover .bg-white-shadow {
  display: block;
}

.bg-white-shadow {
  display: none;
  background: #ffffff;
  box-shadow: inset 0px 4px 14px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  height: 315px;
  width: 332.48px;
  position: absolute; /* Add this line */
  top: 0; /* Add this line */
  right: 2; /* Add this line */
}


.card-container:hover .white-background {
  display: block;
}
.card-container:hover .bg-white-shadow {
  display: block;
  display: block;
  background: #ffffff;
  box-shadow: inset 0px 4px 14px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  height: 315px;
  width: 332.48px;
}

.card-white :hover {
  display: none;
}

.bg-white-shadow {
  display: none;
}

.card-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 150.02%;
  color: black;
  padding-left: 95%;
  padding-right: 46px;
}

.active-tag {
  color: #001400;
  opacity: 1;
  cursor: pointer;
  text-decoration-color: #FAE100;
  text-decoration-line: underline;
  text-underline-offset: 5px;
  transition: color 0.3s ease, text-decoration-color 0.3s ease;
}


.active-tag:hover {
  animation: underlineAnimation 0.4s forwards;
}

.text-white{
  color: white;
}

@keyframes underlineAnimation {
  0% {
    text-decoration-line: none;
    text-underline-offset: 0;
  }
  50% {
    text-decoration-line: underline;
    text-underline-offset: 5px;
  }
  100% {
    text-decoration-line: underline;
    text-underline-offset: 5px;
  }
}



.para-courses{
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }

.course-cat-selection-selected{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.course-cat-selection{
    font-weight: 700;
font-size: 24px;
line-height: 29px;
    color: #001400;
opacity: 0.5;
}

.whiteboard-teacher{
  margin-left: -100px;
}

.whiteboard-teacher{
  margin-left: -100px;
}
@media only screen and (max-width: 1200px) {
.whiteboard-teacher{
  height: auto;
  width: 120%;
}
}
  
@media only screen and (max-width: 1200px) {
  .whiteboard-boy, #student-studying{
    height: auto;
    width: 90%;
  }
}

.tech-support{
  margin-bottom: 30px;
}

@media only screen and (max-width: 1150px) {
  .tech-support{
    height: auto;
    width: 90%;
  }
  }



  .embed-responsive {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.donation-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: 30px;
    height: 100%; /* Changed from fixed height to 100% */
    border: none;
}

@media (max-width: 768px) {
    .embed-responsive {
        padding-bottom: 100%; /* Adjust aspect ratio for smaller screens */
    }
}
 
@media (max-width: 768px) {
	.row.align-items-center {
		flex-direction: column;
	}

	.embed-responsive {
		padding-bottom: 80%; /* Adjust aspect ratio for smaller screens if necessary */
	}

	.col-xl-6, .col-lg-6, .col-md-6, .col-sm-12, .col-xs-12 {
		max-width: 100%;
		padding: 0 15px;
	}

	.button-everywhere {
		width: 100%;
		text-align: center;
		margin-top: 20px;
	}
}
 
.button-everywhere {
	display: block;
	text-align: center;
	margin-top: 20px;
}



.para-question{
  font-size:20px;
  margin-bottom: 50px;

}

.title-question{
  font-weight: 300;
  font-size: 64px;
  line-height: 77px;
  padding-bottom: 32px;
  margin-bottom:-10px;
}

.student-picture{
  height: 658.18px;
  width: 834px;
}

.front-visible{
  z-index: 999;
}

#about-techlit{
  padding-top: 50px;
}

#student-studying{
  height: 120%;
  object-fit: cover;
  align-self: flex-end;
}

.margin-top-90px{
  margin-top:5px;
}
.custom-column {
  padding-left: 120px;
  padding-top: 0;
  padding-right: 0; 
}
hr.rounded {
  border-top: 3px rounded;
  }


.margin-left-70px{
  margin-left:65px;
}

.z-index{
  z-index: 1000;
}

.arrow-icon{
  color: white;
}

.white-background{
  background-color: white;
  border-radius: 20px;
}

.title-about{
  font-weight: 300;
  font-size: 64px;
  line-height: 77px;
  padding-top: 110px;
}

.section-white {
	background: red;
	padding: 70px 0;
}

.team-item{
	background: #1F4C48;
	text-align:center;
	margin:20px 0;
	padding:50px 20px 40px 20px;
	border-radius: 8px 8px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

@media only screen and (max-width: 575px) and (min-width: 300px){
  .team-item{
    width: 80%;
    margin: auto;
  }
}

.team-item:hover{
  background: hsla(54, 100%, 49%, 1);

  background: linear-gradient(225deg, hsla(54, 100%, 49%, 1) 20%, hsla(0, 0%, 100%, 1) 100%);
  
  background: -moz-linear-gradient(225deg, hsla(54, 100%, 49%, 1) 20%, hsla(0, 0%, 100%, 1) 100%);
  
  background: -webkit-linear-gradient(225deg, hsla(54, 100%, 49%, 1) 20%, rgb(255, 243, 173) 100%);
  	color: black;
}

.team-item h3{
	margin:20px 0 1px 0!important;
	color:#FAE100;
	font-size:18px;
	font-weight: 700;
	text-transform:uppercase;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}
.section-subtitle {
	white-space: pre-line;
 }

.team-item:hover h3{
	color:#1F4C48;
}

.team-info {
	display: block;
}

.team-info p{
	color:#FAE100;
	font-style:italic;
}
.team-item:hover .team-info p{
	color:#1F4C48;
	font-style:italic;
}

.team-info::after {
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px; 
    
}

.p-info{
  color:white;
}

.team-item:hover .p-info{
  color:black;
  
}



.course-item{
	background: white;
	text-align:center;
	margin:20px 0;
	padding:50px 20px 40px 20px;
	border-radius: 8px 8px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

@media only screen and (max-width: 575px) and (min-width: 300px){
  .team-item{
    width: 80%;
    margin: auto;
  }
}

.course-item:hover{
  background: hsla(54, 100%, 49%, 1);

  background: linear-gradient(225deg, #1F4C48 20%, hsla(0, 0%, 100%, 1) 100%);
  
  background: -moz-linear-gradient(225deg, #1F4C48 20%, hsla(0, 0%, 100%, 1) 100%);
  
  background: -webkit-linear-gradient(90deg, #1F4C48 5%, #FAE100 95%);
  	color: black;
}

.course-item h3{
	margin:20px 0 1px 0!important;
	color:black;
	font-size:18px;
	font-weight: 700;
	text-transform:uppercase;
	transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-webkit-transition: all .25s ease-in-out;
}

.team-item:hover h3{
	color:#1F4C48;
}

.team-info {
	display: block;
}

.team-info p{
	color:#FAE100;
	font-style:italic;
}
.team-item:hover .team-info p{
	color:#1F4C48;
	font-style:italic;
}

.team-info::after {
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px; 
    
}

.course-p-info{
  color:black;
}

.course-item:hover .course-p-info{
  color:white;
  
}

.course-item:hover h3{
	color:white;
}


.team-img{
	max-width: 140px;
	padding: 6px 6px;
    background-color: #dedede;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

ul.team-icon {
	margin-top: 25px;
}

ul.team-icon li{
	display: inline-block;
	margin:0 3px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

ul.team-icon li a{
	margin: 0;
	display: inline-block;
	padding: 8px 8px;
	width: 34px;
	height: 34px;
	font-size: 18px;
	line-height: 18px;
	background-color: #fff;
	border-radius: 50%;
	color:#fff;
}

ul.team-icon li a.twitter{
	background-color: #4099ff;
}

ul.team-icon li a.pinterest{
	background-color: #C92228;
}

ul.team-icon li a.linkedin{
	background-color: #3b5998;
}

.socials-img{
  height:35px;
    margin:5px;
}


ul.team-icon li a.dribble{
	background-color: #ea4c89;
}

.team-icon li a:hover{
	color:#dedede;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.p-info{
  color: white;
  margin-top: -10px;
}

@media screen and (max-width: 768px) {
  .img-fluidc {
      display: none;
}
}

.subheading-bold-courses {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  padding: 7px;
}

.partnerDesc {
  color: black;
}

.partnerDesc:hover {
  color: black;
}

.container-school {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
  padding: 20px;
  padding-top: 60px;
  gap: 30px;
}

.container-school .mycard {
  position: relative;
  width: 100%;
  height: 100%;
  background: wheat;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 50px rgba(0, 0, 0, 0.5);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  border-radius: 26px;
  text-decoration: none;
  color: black;
}

.container-school .mycard:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 60px rgba(0, 0, 0, 0.7);
}

.container-school .mycard .cimg {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
  overflow: hidden;
}

.container-school .mycard .cimg img {
  width: 100%;
  height: 110%;
  object-fit: cover;
  border-radius: 6px;
  transition: transform 0.4s ease-in-out;
}

.container-school .mycard:hover .cimg img {
  transform: scale(0.95);
}

.container-school .mycard .cdetail {
  padding: 10px 15px;
  text-align: center;
  color: black;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .container-school {
    flex-wrap: wrap;
  }
  
  .container-school .col-md-4 {
    width: 100%;
    margin-bottom: 30px;
  }
}