.accordion-item {
    background-color: white;
    margin-bottom: 10px;
    border-radius: 8px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .accordion-title {
    font-weight: 400 !important;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  