.contactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    padding: 900px 50px;
    background: #f5f5f5;
    font-family: Arial, sans-serif;
    perspective: 2000px;
  }
  
  .backgroundImage {
    position: absolute;
    transform-style: preserve-3d;
  }
  
  .wave {
    top: -50px;
    left: -30px;
    z-index: -1;
  }
  
  .circleDots {
    top: 20%;
    left: 3%;
    z-index: -1;
  }
  
  .circleHorizontal {
    top: 5%;
    right: 2%;
    z-index: -1;
  }
  
  .triangleBlankDots {
    bottom: -50px;
    left: -50px;
    z-index: -1;
  }
  
  .triangleDotsRight {
    bottom: -50px;
    right: 15px;
    z-index: -1;
  }
  
  .formContainer {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 1000px;
    perspective: 2000px;
  }
  
  .formContent {
    text-align: center;
  }
  
  .formContainer h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .formContainer p {
    margin-bottom: 30px;
    color: #666;
  }
  
  .inputGroup {
    margin-bottom: 40px;
  }
  
  .inputGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #444;
  }
  
  .inputGroup input,
  .inputGroup textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .inputGroup textarea {
    resize: none;
  }
  
  .checkboxes {
    margin-bottom: 20px;
  }
  
  .checkboxes label {
    display: block;
    margin-bottom: 10px;
    color: #555;
  }
  
  .checkboxes input[type="checkbox"]:checked + label:before {
    background-color: #fae100;
  }
  
  button[type="submit"] {
    background-color: black;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.4s;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .successMessage {
    color: green;
    margin-top: 20px;
  }
  